import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import type { ValorantMatchWeaponStats } from "@/data-models/valorant-match-weapon-stats.mjs";
import type { ValorantProfile } from "@/data-models/valorant-profile.mjs";
import {
  getNameTag,
  getPlayerMatchStats,
  isQueueDeathmatch,
} from "@/game-val/utils.mjs";
import WeaponStatsTable from "@/game-val/WeaponStatsTable.jsx";
import { DataTableNoResults } from "@/shared/DataTable.jsx";
import { TabLoading } from "@/shared/MatchTileExpand.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Weapons({
  matchId,
  profile,
  playerWeaponStats,
}: {
  matchId: string;
  profile: ValorantProfile;
  playerWeaponStats: ValorantMatchWeaponStats;
}) {
  const state = useSnapshot(readState);
  const match = state.val.match[matchId];

  const queueName: string = match?.queue;
  const isDeathmatch = isQueueDeathmatch(queueName);
  const valConstants = state.val?.content;

  const currentPlayerStats = getPlayerMatchStats(match, profile);
  const agentId = currentPlayerStats?.agent?.uuid;
  const agentKey =
    valConstants?.agents &&
    valConstants.agents
      .find(
        (agentObj) => agentObj.uuid.toLowerCase() === agentId?.toLowerCase(),
      )
      ?.name?.toLowerCase()
      ?.replaceAll("/", "");

  if (!match) return <TabLoading />;
  if (isDeathmatch || !valConstants || !currentPlayerStats)
    return <DataTableNoResults />;

  return (
    <WeaponStatsTable
      isDeathmatch={isDeathmatch}
      agentName={agentKey}
      matchId={match.gameId}
      nameTag={getNameTag(profile.gameName, profile.tagLine)}
      matchWeaponStats={playerWeaponStats}
    />
  );
}

export default Weapons;
